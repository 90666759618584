import React from "react";
import { TableWrapper } from "../styles/theme";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const table = props => {
  return (
    <TableWrapper>
      <Table className="table table-bordered">
        <Thead>
          <Tr>
            {props.headers.map((header, index) => (
              <Th scope="col" key={index}>
                {header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {props.data.map((value, valueIndex) => (
            <Tr key={valueIndex}>
              {Object.keys(value).map(key => (
                <Td key={key}>{value[key] || "N/A"}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableWrapper>
  );
};

export default table;
