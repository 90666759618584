import React from "react";
import { withRouter } from "react-router-dom";

import { TopBarHeader } from "../../styles/theme";
import { TopBarWrapper } from "../../styles/layout-styles";

class Topbar extends React.Component {
  state = {
    title: "",
  };
  getTitle = ({ pathname }) => {
    // Set the title of the page based on what is in the path.
    if (pathname.includes("accounts")) {
      this.setState({ title: `Accounts` });
    }
    if (pathname.includes("messages")) {
      this.setState({ title: `Replies` });
    }
    if (pathname.includes("reports")) {
      this.setState({ title: `Reports` });
    }
    if (pathname.includes("sent")) {
      this.setState({ title: `Sent Messages` });
    }
  };
  componentDidMount() {
    this.getTitle(this.props.location);
  }
  componentDidUpdate(prevProps, nextProps) {
    if (prevProps.location.pathname !== this.props.location.pathname)
      this.getTitle(this.props.location);
  }
  render() {
    return (
      <TopBarWrapper>
        <TopBarHeader>{this.state.title}</TopBarHeader>
        {/* <Button style={{ float: `right` }} active>
          My Account
        </Button> */}
      </TopBarWrapper>
    );
  }
}

export default withRouter(Topbar);
