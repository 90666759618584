import React, { Component } from "react";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import Table from "../../components/table";
import { H2 } from "../../styles/theme";
import { refreshTimeout } from "../../helpers";

import { getAccounts } from "../../data/fetchers";
import { MoonLoader } from "react-spinners";
const headers = [
  "Company",
  "Contact Name",
  "Email",
  // "Total Replies",
  "View Account"
];

class Accounts extends Component {
  state = {
    loading: true,
    data: []
  };

  loadAccountTable = async (hashed, loading) => {
    if (loading) {
      this.setState({ ...this.state, loading: true });
    }
    try {
      const results = await getAccounts(hashed);
      const data = results.map(async element => ({
        company: element.title[0].value,
        // TODO: We should probably make this a required field on the backend, but just in case!
        contactName: element.field_contact_name
          ? element.field_contact_name[0].value
          : "N/A",
        email: element.field_email ? element.field_email[0].value : "N/A",
        //new: await numberOfNewMessages(hashed, element.nid[0].value),
        id: (
          <Link to={`/accounts/view/${element.nid[0].value}`}>
            View Account
          </Link>
        )
      }));
      Promise.all(data).then(data => this.setState({ loading: false, data }));
    } catch (e) {
      console.log(e);
    }
  };

  refreshData = debounce(async hashed => {
    await this.loadAccountTable(hashed, false);
  }, refreshTimeout);

  async componentDidMount() {
    const hashed = sessionStorage.getItem("auth");
    if (hashed) {
      await this.loadAccountTable(hashed, true);
    }
  }
  async componentDidUpdate() {
    const hashed = sessionStorage.getItem("auth");
    if (hashed) {
      await this.refreshData(hashed);
    }
  }
  componentWillUnmount() {
    // Kill our message refresh if we move away from page.
    this.refreshData.cancel();
  }
  render() {
    if (this.state.loading) return <MoonLoader/>;
    return (
      <div>
        <Link
          style={{ float: `right`, fontWeight: `500` }}
          to={`/accounts/create`}
        >
          Create New Account
        </Link>
        <H2>Accounts Page</H2>
        <section style={{ margin: `50px 0px` }}>
          <Table headers={headers} data={this.state.data} />
        </section>
      </div>
    );
  }
}

export default Accounts;
