import React from "react";

const Reports = () => {
  return (
    <div>
      <h2>Reports Page</h2>
    </div>
  );
};

export default Reports;
