export const formatDate = date => {
  return new Date(date).toLocaleDateString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });
};

export const convertTimestamp = timestamp => {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  var date = new Date(timestamp * 1000);

  // Create an array with the current month, day and time
  var dateValues = [
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear(),
  ];

  // Create an array with the current hour, minute and second
  var timeValues = [
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];

  // Determine AM or PM suffix based on the hour
  var suffix = (timeValues[0] < 12) ? "AM" : "PM";

  // Convert hour from military time
  timeValues[0] = (timeValues[0] < 12) ? timeValues[0] : timeValues[0] - 12;

  // If hour is 0, set it to 12
  timeValues[0] = timeValues[0] || 12;

  // If seconds and minutes are less than 10, add a zero
  for (var i = 1; i < 3; i++) {
    if (timeValues[i] < 10) {
      timeValues[i] = "0" + timeValues[i];
    }
  }

  // Return the formatted string
  return dateValues.join("/") + " " + timeValues.join(":") + " " + suffix;
}


// weird way drupal parses time ranges. $hours * 3600 + $minutes * 60 + $seconds;
export const timeToSeconds = time => {
  const timeArray = time.split(":");
  return timeArray[0] * 3600 + timeArray[1] * 60;
};

export const refreshTimeout = 30000;

export const getTimezoneName = timezone => {
  switch (timezone) {
    case "America/New_York":
      return "Eastern";
    case "America/Chicago":
      return "Central";
    case "America/Denver":
      return "Mountain";
    case "America/Los_Angeles":
      return "Pacific";
    default:
      return "No Timezone";
  }
};

// Thank you random internet person!
export const secondsToTime = seconds =>
  new Date(seconds * 1000).toISOString().substr(11, 8);
