import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, H2, FormMessage } from "../../styles/theme";
import { FormInputWrapper } from "../../styles/layout-styles";
import { createNewAccount } from "../../data/fetchers";
import { timeToSeconds } from "../../helpers";
class AccountCreate extends Component {
  render() {
    const { history } = this.props;

    return (
      <section>
        <H2>Create a new Account</H2>
        <Formik
          initialValues={{
            customerName: "",
            // customerPhone: "",
            customerEmail: "",
            customerContactName: "",
            customerNotes: "",
            autoReply: false,
            autoReplyText: "",
            hoursStart: "",
            hoursEnd: "",
            timezone: "",
            country_code: "US",
            address_line1: "",
            locality: "",
            administrative_area: "",
            postal_code: ""
          }}
          onSubmit={async (
            values,
            { setSubmitting, setErrors, setStatus, resetForm }
          ) => {
            const hashed = sessionStorage.getItem("auth");
            if (hashed) {
              const data = {
                _links: {
                  type: {
                    href: "https://thumpsms.mystack.co/rest/type/node/customer"
                  }
                },
                type: {
                  target_id: "customer"
                },
                title: {
                  value: values.customerName
                },
                field_customer_auto_reply: { value: values.autoReply },
                field_customer_auto_reply_text: { value: values.autoReplyText },
                field_customer_client_notes: {
                  value: values.customerNotes
                },
                field_contact_name: { value: values.customerContactName },
                field_email: { value: values.customerEmail },
                field_customer_operating_hours: {
                  value: {
                    from: parseInt(timeToSeconds(values.hoursStart)),
                    to: parseInt(timeToSeconds(values.hoursEnd))
                  }
                },
                field_customer_timezone: { value: values.timezone },
                field_address: {
                  value: {
                    country_code: values.country_code,
                    administrative_area: values.administrative_area,
                    locality: values.locality,
                    postal_code: values.postal_code,
                    address_line1: values.address_line1
                  }
                }
              };
              try {
                const results = await createNewAccount(hashed, data);
                if (results) {
                  setSubmitting(false);
                  resetForm();
                  // Redirect to newly created account page.
                  history.push("/accounts/view/" + results.nid[0].value);
                }
              } catch (e) {
                setSubmitting(false);
                setErrors({ error: e.message });
              }
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
            }
          }}
          render={props => {
            return (
              <Form>
                {props.errors && props.errors.error && (
                  <FormMessage error>Error: {props.errors.error}</FormMessage>
                )}
                {props.status && props.status && (
                  <FormMessage success>{props.status}</FormMessage>
                )}
                <FormInputWrapper>
                  <label htmlFor="customerName">Customer Name</label>
                  <Field type="text" name="customerName" />
                  <ErrorMessage name="customerName" />
                </FormInputWrapper>
                {/* <FormInputWrapper>
                  <label htmlFor="customerPhone">Customer Phone Number</label>
                  <Field type="text" name="customerPhone" />
                  <ErrorMessage name="customerPhone" />
                </FormInputWrapper> */}
                <FormInputWrapper>
                  <label htmlFor="address_line1">Address</label>
                  <Field
                    style={{ width: `225px` }}
                    type="text"
                    name="address_line1"
                  />
                  <ErrorMessage name="address_line1" />
                </FormInputWrapper>
                <div style={{ display: `flex`, flexWrap: `wrap` }}>
                  <FormInputWrapper>
                    <label htmlFor="locality">City</label>
                    <Field type="text" name="locality" />
                    <ErrorMessage name="locality" />
                  </FormInputWrapper>
                  <FormInputWrapper>
                    <label htmlFor="administrative_area">State</label>
                    <Field component="select" name="administrative_area">
                      <option value="">Select State</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </Field>
                    <ErrorMessage name="administrative_area" />
                  </FormInputWrapper>
                  <FormInputWrapper>
                    <label htmlFor="postal_code">Zip Code</label>
                    <Field type="text" name="postal_code" />
                    <ErrorMessage name="postal_code" />
                  </FormInputWrapper>
                </div>
                <FormInputWrapper>
                  <label htmlFor="customerEmail">Customer Email</label>
                  <Field type="text" name="customerEmail" />
                  <ErrorMessage name="customerEmail" />
                </FormInputWrapper>
                <FormInputWrapper>
                  <label htmlFor="customerContactName">Contact Name</label>
                  <Field type="text" name="customerContactName" />
                  <ErrorMessage name="customerContactName" />
                </FormInputWrapper>
                <FormInputWrapper>
                  <label htmlFor="customerNotes">Customer Notes</label>
                  <br />
                  <Field
                    component="textarea"
                    name="customerNotes"
                    rows="6"
                    cols="50"
                  />
                  <ErrorMessage name="customerNotes" />
                </FormInputWrapper>
                <FormInputWrapper>
                  <label htmlFor="autoReply">Auto-Reply</label>
                  <Field type="checkbox" name="autoReply" />
                  <ErrorMessage name="autoReply" />
                </FormInputWrapper>
                <FormInputWrapper>
                  <label htmlFor="autoReplyText">Auto-Reply Text</label>
                  <br />
                  <Field
                    component="textarea"
                    name="autoReplyText"
                    rows="6"
                    cols="50"
                  />
                  <ErrorMessage name="autoReplyText" />
                </FormInputWrapper>
                <FormInputWrapper>
                  <label htmlFor="hoursStart">Operating Hours Start</label>
                  <Field type="time" name="hoursStart" />
                  <ErrorMessage name="hoursStart" />
                </FormInputWrapper>
                <FormInputWrapper>
                  <label htmlFor="hoursEnd">Operating Hours End</label>
                  <Field type="time" name="hoursEnd" />
                  <ErrorMessage name="hoursEnd" />
                </FormInputWrapper>
                <FormInputWrapper>
                  <label htmlFor="timezone">Timezone</label>
                  <Field component="select" name="timezone">
                    <option value="">Select a Timezone</option>
                    <option value="America/New_York">Eastern</option>
                    <option value="America/Chicago">Central</option>
                    <option value="America/Denver">Mountain</option>
                    <option value="America/Los_Angeles">Pacific</option>
                  </Field>
                  <ErrorMessage name="timezone" />
                </FormInputWrapper>

                <Button
                  style={{ width: `50%` }}
                  onClick={() => props.submitForm()}
                  active={!props.isSubmitting}
                  submitting={props.isSubmitting}
                >
                  Create New Customer
                </Button>
              </Form>
            );
          }}
        />
      </section>
    );
  }
}

export default AccountCreate;
