import styled from "styled-components";
import logo from "../layout/thump-logo.png";
// Add theme variables here.
const green = `#6BB74C`;
const white = `#F9F9F9`;
const black = `#232323`;
const grey = `#A9A9A9`;
const darkGrey = `#565050`;
const red = `#d00303`;

export default {
  sidebarWidth: `195px`,
  topBarHeight: `75px`,
  green,
  white,
  black,
  grey,
  red,
  darkGrey,
  defaultBorder: `4px solid ${green}`,
  tableBorder: `1px solid ${grey}`,
  fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif`,
  fontStyle: `normal`,
  fontWeight: `200`,
  logo
};

export const Body = styled.main`
  margin-left: ${props => props.theme.sidebarWidth};
  padding: 10px;
`;
export const Button = styled.div`
  cursor: pointer;
  border: ${props => props.theme.defaultBorder};
  color: ${props => props.theme.white};
  background: ${({ active, theme }) => (active ? theme.green : theme.black)};
  pointer-events: ${({ submitting }) => (submitting ? "none" : "auto")};
  opacity: ${({ submitting }) => (submitting ? ".5" : "1")};
  padding: 10px 30px;
  white-space: nowrap;
  text-align: center;

  &:hover {
    background: ${props => props.theme.green};
    opacity: ${({ active }) => (active ? 0.8 : 1)};
  }
`;

export const H1 = styled.h1`
  font-size: 48px;
  /* font-family: ${props => props.theme.fontFamily};
  font-style: ${props => props.theme.fontStyle};
  font-weight: ${props => props.theme.fontWeight}; */
`;

export const TopBarHeader = styled(H1)`
  width: 75%;
  float: left;
`;

export const H2 = styled.h2`
  font-size: 36px;
  border-bottom: ${({ theme }) => theme.tableBorder};
  margin: 5px;
`;

export const H3 = styled.h3`
  font-size: 24px;
  font-weight: 500;
`;

export const TableWrapper = styled.section`
  table {
    border-collapse: collapse;
    border-style: hidden;

    td,
    th {
      border: ${props => props.theme.tableBorder};
      padding: 20px;
    }
    th {
      font-weight: 500;
      text-align: left;
    }
  }
`;

export const Label = styled.label`
  color: ${props => props.theme.darkGrey};
  margin: 5px;
`;

export const FormMessage = styled.p`
  color: ${({ success }) => success && (props => props.theme.green)};
  color: ${({ error }) => error && (props => props.theme.red)};
  background: ${({ error }) => error && `#fbe0e0`};
  background: ${({ success }) => success && `#e7ffdd`};
  border: ${({ error }) => error && (props => `2px solid ${props.theme.red}`)};
  border: ${({ success }) =>
    success && (props => `2px solid ${props.theme.green}`)};
  font-weight: 500;
  padding: 10px;
`;
