import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";

// import Main from "./pages/main"; // Maybe we will want a root dash at some point.
import AccountCreate from "./pages/accounts/create";
import AccountEdit from "./pages/accounts/edit";
import Account from "./pages/accounts/view";
import ContactManager from "./pages/accounts/contact_manager";
import Accounts from "./pages/accounts";
import Message from "./pages/messages/view";
import Messages from "./pages/messages/";
import Reports from "./pages/reports";
import SentMessages from "./pages/messages/sent";

import Layout from "./layout/layout";
import theme from "./styles/theme";
import GlobalStyle from "./styles/global-styles";
import SignIn from "./components/signin";

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <SignIn>
          <>
            <GlobalStyle />
            <Router>
              <Layout>
                <Switch>
                  <Route path="/accounts/create" component={AccountCreate} />
                  <Route path="/accounts/edit/:id" component={AccountEdit} />
                  <Route path="/accounts/view/:id" component={Account} />
                  <Route
                    path="/accounts/contact_manager/:id"
                    component={ContactManager}
                  />
                  <Route path="/accounts/" component={Accounts} />
                  <Route path="/sent" component={SentMessages} />
                  <Route path="/messages/view/:id" component={Message} />
                  <Route path="/messages/" component={Messages} />
                  <Route path="/Reports/" component={Reports} />
                  <Redirect to="/messages/" />
                </Switch>
              </Layout>
            </Router>
          </>
        </SignIn>
      </ThemeProvider>
    );
  }
}

export default App;
