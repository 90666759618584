import React, { Component } from "react";
import styled from "styled-components";
import { Button } from "../styles/theme";

const Textarea = styled.textarea`
  overflow: auto;
  width: 600px;
  height: 120px;
  border: 3px solid ${(props) => props.theme.green};
  padding: 5px;
`;
class Message extends Component {
  messagesEndRef = React.createRef();
  componentDidMount() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    const { onChange, onClick } = this.props;
    return (
      <>
        <Textarea
          ref={this.messagesEndRef}
          placeholder="Enter reply text here."
          onChange={onChange}
        />
        <Button onClick={onClick} style={{ width: `25%` }} active>
          Submit
        </Button>
      </>
    );
  }
}

export default Message;
