import React, { Component } from "react";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import { getAllMessages } from "../../data/fetchers";
import { refreshTimeout, convertTimestamp } from "../../helpers";

import { Grommet, Box, ThemeContext, grommet } from "grommet";
import { DataTable } from "grommet/components/DataTable";
import { MoonLoader } from "react-spinners";
const columnConfig = [
  {
    property: "created",
    header: "Message Date",
  },
  // {
  //   property: "lastMessage",
  //   header: "Last Reply Date",
  // },
  {
    property: "title",
    header: "Company",
  },
  // {
  //   property: "user",
  //   header: "User",
  // },
  {
    property: "phone",
    header: "Phone Number",
  },
  {
    property: "nid",
    header: "View Account",
  },
  {
    property: "sid",
    header: "View Messages",
  },
];

class Index extends Component {
  state = {
    loading: true,
    data: null,
  };

  processResults = async (results, hashed) =>
    new Promise((resolve, reject) =>
      resolve(
        results.map(async (record) => {
          // const { phone, first_name, last_name, last_comment_date } =
          //   await JSON.parse(data);
          // return {
          //   created: formatDate(created),
          //   lastMessage: formatDate(last_comment_date),
          //   title,
          //   user:
          //     first_name !== undefined ? `${first_name} ${last_name}` : "N/A",
          //   phone,
          //   nid: (
          //     <Link to={`/accounts/view/${field_customer}`}>View Account</Link>
          //   ),
          //   sid: <Link to={`/messages/view/${sid}`}>View Message</Link>,
          // };
          return {
            created: convertTimestamp(record.created),
            title: record.customer_name,
            phone: record.contact_phone,
            nid: (
              <Link to={`/accounts/view/${record.customer_id}`}>View Account</Link>
            ),
            sid: <Link to={`/messages/view/${record.submission_id}`}>View Message</Link>,
          };
        })
      )
    );

  sortByDate = ({ lastMessage: a }, { lastMessage: b }) =>
    new Date(b) - new Date(a);

  loadMessageData = async (hashed) => {
    try {
      const messages = await getAllMessages(hashed);

      const data = await this.processResults(
        messages,
        hashed
      );

      Promise.all(data).then((values) =>
        this.setState({
          loading: false,
          data: values.sort(this.sortByDate),
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  refreshMessages = debounce(
    (hashed) => this.loadMessageData(hashed),
    refreshTimeout
  );

  async componentDidMount() {
    const hashed = sessionStorage.getItem("auth");
    if (hashed) {
      this.loadMessageData(hashed);
    }
  }

  async componentDidUpdate() {
    const hashed = sessionStorage.getItem("auth");

    if (hashed) {
      await this.refreshMessages(hashed);
    }
  }

  componentWillUnmount() {
    this.refreshMessages.cancel();
  }
  render() {
    const { loading, data } = this.state;

    if (loading) return <MoonLoader/>;
    return (
      <Grommet theme={grommet}>
        <div>
          <p>A listing of recent replies. Excluding any opt-out messages. Click view message next to any message to engage in full thread.</p>
          <section style={{ margin: `50px 0px` }}>
            <ThemeContext.Extend
              value={{
                dataTable: {
                  primary: { weight: "lighter" },
                  header: { font: { weight: "bold" } },
                },
              }}
            >
              <Box>
                <DataTable
                  paginate
                  columns={columnConfig}
                  data={data}
                  // primaryKey="created"
                  step={25}
                  border={false}
                />
              </Box>
            </ThemeContext.Extend>
          </section>
        </div>
      </Grommet>
    );
  }
}

export default Index;
