import React, { Component } from "react";
import { Formik } from "formik";
import { LoginWrapper, Logo } from "../styles/layout-styles";
import { Button } from "../styles/theme";
class SignIn extends Component {
  state = {
    auth: false
  };

  componentDidMount() {
    if (sessionStorage.getItem("auth") && !this.state.auth) {
      this.setState({ auth: true });
    }
  }
  render() {
    if (this.state.auth) return this.props.children;
    return (
      <LoginWrapper>
        <div className="login-inner">
          <Logo />
          <h3>Please Login</h3>
          <Formik
            initialValues={{ user: "", password: "" }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              const hashed = btoa(`${values.user}:${values.password}`);

              const host = `https://thumpsms.mystack.co/`;
              const endpoint = `/api/v1/customers?_format=hal_json`;
              const headers = new Headers({
                Authorization: `Basic ${hashed}`,
                Accept: "application/hal+json"
              });

              const results = await fetch(`${host}${endpoint}`, {
                method: "GET",
                headers
              })
                .catch(error => error)
                .then(async res => {
                  if (res.status === 403) {
                    this.setState({ auth: false });
                    return false;
                  }
                  sessionStorage.setItem("auth", hashed);
                  this.setState({ auth: true });
                  return true;
                });
              if (!results)
                setErrors({ result: "Username or Password incorrect." });
              setSubmitting(false);
            }}
            render={props => {
              return (
                <>
                  {props.errors.result && (
                    <div style={{ color: `red` }}>{props.errors.result}</div>
                  )}
                  <form onSubmit={props.handleSubmit}>
                    <input
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.user}
                      name="user"
                      placeholder="User Name"
                    />
                    <input
                      type="password"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.password}
                      name="password"
                      placeholder="Password"
                    />
                    {/* This just makes hitting enter work so we don't have to create 2 button styles.  */}
                    <button style={{ display: "none" }} type="submit">
                      enter
                    </button>
                    <Button
                      onClick={() => props.submitForm()}
                      type="button"
                      active
                      style={{ marginTop: `10px` }}
                    >
                      Submit
                    </Button>
                  </form>
                </>
              );
            }}
          />
        </div>
      </LoginWrapper>
    );
  }
}

export default SignIn;
