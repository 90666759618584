import React, { useState } from "react";
import { Box, Button, FileInput, Heading } from "grommet";
import { Label } from "../styles/theme";
import Papa from "papaparse";
import { createCustomerContacts } from "../data/fetchers";


const ContactImport = ({ setImportDisplay, customer, cancelImport }) => {
  const [importData, setImportData] = useState([]);
  const [message, setMessage] = useState("");

  return (
    <Box margin="medium">
      <Heading level="3">Import Contacts</Heading>
      {message && <Label>{message}</Label>}
      <FileInput
        accept=".csv"
        name="file"
        onChange={async (event) => {
          const fileList = event.target.files;
          for (let i = 0; i < fileList.length; i += 1) {
            Papa.parse(fileList[i], {
              header: false,
              step: (l) => {
                let empty = true;
                for (var i in l) {
                  if (i) {
                    empty = false;
                  }
                }
                if (!empty) {
                  const temp = {
                    firstname: l.data[0],
                    lastname: l.data[1],
                    phone: l.data[2],
                    email: l.data[3],
                    customer,
                  };
                  importData.push(temp);
                  setImportData(importData);
                }
              },
              complete: (results) => {
                if (results.errors.length === 0) {
                  const finalData = importData.filter(
                    (d) => d.phone && d.phone !== "phone"
                  );
                  setImportData(finalData);
                  setMessage(`Ready to import ${finalData.length} contacts`);
                }
              },
            });
          }
        }}
      />
      <Box width="medium" margin="small" direction="row" gap="small">
        <Button
          label={`Import contacts.`}
          primary
          size="medium"
          disabled={importData.length === 0}
          onClick={async (e) => {
            const hashed = sessionStorage.getItem("auth");
            if (hashed) {
              if (importData.length > 0) {

                const ids = await createCustomerContacts(hashed, importData); // @Todo Catch errors.
                setImportDisplay(false, ids);
                //window.location.reload();
              }
            }
          }}
        />
        <Button
          color="status-critical"
          label={`Cancel`}
          primary
          size="medium"
          onClick={(e) => cancelImport(e)}
        />
      </Box>
    </Box>
  );
};

export default ContactImport;
