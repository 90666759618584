import React, { Component } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import debounce from "lodash.debounce";
import { getSubmissionCommentsByID } from "../data/fetchers";
import { H3, Label } from "../styles/theme";
import { CommentsWrapper, CommentWrapper } from "../styles/layout-styles";
import { withTheme } from "styled-components";
import { refreshTimeout } from "../helpers";
class Comments extends Component {
  state = {
    loading: true,
    data: null,
    id: null
  };
  messageDebounce = debounce(async (hashed, id) => {
    // Need to re-fetch data and save it to the state.
    this.setState({ ...this.state, loading: true });
    const newState = { ...this.state };
    newState.data = await getSubmissionCommentsByID(hashed, id);
    newState.loading = false;

    this.setState(newState);
  }, refreshTimeout);
  async componentDidMount() {
    const hashed = sessionStorage.getItem("auth");
    const { id } = this.props.match.params;
    if (hashed) {
      const data = await getSubmissionCommentsByID(hashed, id);

      this.setState({ loading: false, data, id });
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    const hashed = sessionStorage.getItem("auth");
    const { id } = this.props.match.params;
    // Had to get a little crazy here to support triggering an update from the parent component while still being able to update our comments regularly from this component.
    if (this.props.trigger) {
      const newState = { ...this.state };
      newState.data = await getSubmissionCommentsByID(hashed, id);
      this.props.resolveTrigger();
      this.setState(newState);
    }
    await this.messageDebounce(hashed, id);
  }
  render() {
    const { data, loading } = this.state;

    if (loading)
      return (
        <CommentsWrapper>
          <H3>Comments</H3>
          <PulseLoader color={this.props.theme.green} />
        </CommentsWrapper>
      );
    if (data.length === 0)
      return (
        <CommentsWrapper>
          <H3>Comments</H3>
          <p>No Comments found.</p>
        </CommentsWrapper>
      );
    return (
      <CommentsWrapper>
        <H3>Comments</H3>
        <section>
          {data.map(({ field_comment, title }, index) => (
            <CommentWrapper key={index}>
              <Label>{title[0].value}</Label>
              <p>{field_comment[0].value}</p>
            </CommentWrapper>
          ))}
        </section>
      </CommentsWrapper>
    );
  }
}

export default withTheme(Comments);
