import styled from "styled-components";

export const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    margin: 10px 0;
  }
`;

export const Sidebar = styled.nav`
  width: ${props => props.theme.sidebarWidth};
  height: 100vh;
  background: ${props => props.theme.black};
  position: fixed;
  top: 0;
  padding: 10px;
  box-sizing: border-box;
`;
export const TopBarWrapper = styled.header`
  padding: 27px 20px;
  margin-left: ${props => props.theme.sidebarWidth};
  position: sticky;
  top: 0;
  background: ${props => props.theme.white};
  border-bottom: ${props => props.theme.defaultBorder};
  color: #777;
  z-index: 99999;
  height: ${props => props.theme.topBarHeight};
`;

export const Logo = styled.div`
  height: ${props => props.theme.topBarHeight};
  background-image: url(${props => props.theme.logo});
  background-repeat: no-repeat;
  background-position: center;
`;

export const SidebarLogo = styled(Logo)`
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.grey};
  margin-bottom: 50px;
  margin-top: 10px;
  background-position: unset;
`;

export const SubmissionWrapper = styled.div`
  .js-form-item-customerid, .js-form-item-last-comment-date {
    display: none;
  }
`

export const LoginWrapper = styled.div`
  height: 100vh;
  text-align: center;
  position: relative;
  background-color: ${({ theme }) => theme.black};

  .login-inner {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h3 {
      width: 50%;
      margin: auto;
      color: ${props => props.theme.green};
    }
    form {
      width: 50%;
      margin: auto;

      input {
        background: ${props => props.theme.white};
        line-height: 38px;
        border: ${props => props.theme.tableBorder};
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        color: #666;
        font-size: 15px;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
        padding: 0 20px;
        margin: 5px 0;
      }
    }
  }
`;

export const CommentsWrapper = styled.div`
  padding: 5px;
  margin-top: 25px;
  width: 80%;
  section {
    margin-top: 20px;
  }
  .css-0 {
    text-align: center;
    margin-top: 30px;
    width: 30%;
  }
`;
export const CommentWrapper = styled.div`
  margin: 20px 10px;
  border-bottom: ${props => props.theme.tableBorder};
  p {
    margin: 10px 0 10px 25px;
  }
`;

export const FormInputWrapper = styled.div`
  padding: 5px;
  margin: 10px;
  label {
    color: ${props => props.theme.black};
    margin-right: 10px;
  }
  textarea {
    margin: 10px 0;
    padding: 5px;
  }
  input {
    padding: 5px;
  }
`;
