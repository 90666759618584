import React from "react";
import { Link, withRouter } from "react-router-dom";

import { Button } from "../../styles/theme";
import { NavWrapper } from "../../styles/layout-styles";

const handleActive = (path, button) =>
  path.indexOf(button) !== -1 ? true : false;

const Navigation = ({ location }) => {
  return (
    <NavWrapper>
      <Link to={`/messages`}>
        <Button active={handleActive(location.pathname, "messages")}>
          Replies
        </Button>
      </Link>
      <Link to={`/sent`}>
        <Button active={handleActive(location.pathname, "sent")}>
          Sent Messages
        </Button>
      </Link>
      <Link to={`/accounts`}>
        <Button active={handleActive(location.pathname, "accounts")}>
          Accounts
        </Button>
      </Link>
      <a target={`_blank`} href="https://www.twilio.com/console/sms/dashboard">
        <Button>Reports</Button>
      </a>

      <Button
        style={{ margin: `10px 0` }}
        onClick={() => {
          sessionStorage.removeItem("auth");
          window.location.reload();
        }}
      >
        Log Out
      </Button>
    </NavWrapper>
  );
};

export default withRouter(Navigation);
