import React, { Component } from "react";
import { Link } from "react-router-dom";

import { H2 } from "../../styles/theme";
import { getMessages } from "../../data/fetchers";
import { formatDate } from "../../helpers";

import { Grommet, Box, ThemeContext, grommet } from "grommet";
import { DataTable } from "grommet/components/DataTable";
import { MoonLoader } from "react-spinners";
const columnConfig = [
  {
    property: "created",
    header: "Date Sent",
  },
  {
    property: "customer",
    header: "Company Name",
    render: ({ customer, customer_id }) => (
      <Link to={`/accounts/view/${customer_id}`}>{`${customer}`}</Link>
    ),
  },
  {
    property: "message",
    header: "Message",
    render: ({ message }) => (
      <Box dangerouslySetInnerHTML={{ __html: message }} />
    ),
  },
  {
    property: "image",
    header: "Image",
    render: ({ image }) => (
      <Box dangerouslySetInnerHTML={{ __html: "<img width='150px' src='" + image + "'></img>" }} />
    ), 
  },
  {
    property: "count",
    header: "Number of Recipients",
  },
];

class SentMessages extends Component {
  state = {
    loading: true,
    data: null,
  };

  processResults = async (results, hashed) =>
    new Promise((resolve, reject) =>
      resolve(
        results.map(
          (
            {
              created,
              field_customer,
              webform_submission_value,
              webform_submission_value_1,
              field_customer_1,
              webform_submission_value_2
            },
            i
          ) => {
            return {
              id: i,
              created: formatDate(created),
              customer: field_customer,
              customer_id: field_customer_1,
              message: webform_submission_value,
              count: webform_submission_value_1,
              image: webform_submission_value_2
            };
          }
        )
      )
    );

  loadMessageData = async (hashed) => {
    try {
      const data = await this.processResults(await getMessages(hashed), hashed);
console.log(data);
      Promise.all(data).then((values) =>
        this.setState({
          loading: false,
          data: values,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  async componentDidMount() {
    const hashed = sessionStorage.getItem("auth");
    if (hashed) {
      this.loadMessageData(hashed);
    }
  }

  render() {
    const { loading, data } = this.state;
    if (loading) return <MoonLoader/>;
    return (
      <Grommet theme={grommet}>
        <div>
          <H2>Sent Messages</H2>
          <section style={{ margin: `50px 0px` }}>
            <ThemeContext.Extend
              value={{
                dataTable: {
                  primary: { weight: "lighter" },
                  header: { font: { weight: "bold" } },
                },
              }}
            >
              <Box>
                <DataTable
                  paginate
                  columns={columnConfig}
                  data={data}
                  primaryKey="id"
                  step={25}
                  border={false}
                />
              </Box>
            </ThemeContext.Extend>
          </section>
        </div>
      </Grommet>
    );
  }
}

export default SentMessages;
