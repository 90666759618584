import React from "react";
import { Link } from "react-router-dom";
import Navigation from "./components/navigation";
import { Body } from "../styles/theme";
import { Sidebar, SidebarLogo } from "../styles/layout-styles";
import TopBar from "./components/topbar";
const layout = props => {
  return (
    <div>
      <Sidebar>
        <Link to={`/`}>
          <SidebarLogo />
        </Link>
        <Navigation />
      </Sidebar>
      <TopBar />
      <Body>{props.children}</Body>
    </div>
  );
};

export default layout;
