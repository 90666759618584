import React from "react";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";
import debounce from "lodash.debounce";
import {
  getSubmissionsByCustomer,
  getAccountById,
  host,
} from "../../data/fetchers";
import Table from "../../components/table";
import { H2, H3, Label } from "../../styles/theme";
import {
  refreshTimeout,
  getTimezoneName,
  secondsToTime,
} from "../../helpers";
import { MoonLoader } from "react-spinners";

const AccountSection = styled.section`
  padding: 10px;
  margin: 15px 0px 50px 0px;
`;
const Notes = styled.div`
  margin: 15px 0px 15px 15px;
`;
const AccountPageWrapper = styled.div`
  /* .field-label {
    background-color: ${(props) => props.theme.grey};
    padding: 5px;
    color: ${(props) => props.theme.white} !important;
    font-size: 1.3rem;
  } */
`;
class AccountView extends React.Component {
  state = {
    loading: true,
    data: [],
  };
  refreshMessages = debounce(async (hashed, id) => {
    const newState = { ...this.state };

    // Grab the submissions byt customer
    newState.data.submissions_by_customer  = await getSubmissionsByCustomer(hashed,id);
    console.log(newState.data.submissions_by_customer);

    // Set the data in the state.
    this.setState(newState);
  }, refreshTimeout);

  async componentDidMount() {
    const hashed = sessionStorage.getItem("auth");
    const { id } = this.props.match.params;

    if (hashed) {
      // Grap the account data.
      const data = await getAccountById(hashed, id);

      // Grab the submissions byt customer
      data.submissions_by_customer = await getSubmissionsByCustomer(hashed,id);

      // Set the data in the state.
      this.setState({ loading: false, data });
    }
  }
  async componentDidUpdate() {
    const hashed = sessionStorage.getItem("auth");
    const { id } = this.props.match.params;
    if (hashed) {
      await this.refreshMessages(hashed, id);
    }
  }
  componentWillUnmount() {
    // Kill our message refresh if we move away from page.
    this.refreshMessages.cancel();
  }
  render() {
    if (this.state.loading) return <MoonLoader></MoonLoader>;

    // OMG initalizing default values while destructuring an object is the best new javascript thing!
    const {
      title,
      submissions_by_customer,
      field_customer_client_notes = [{ value: "" }],
      field_address = [
        {
          address_line1: "",
          locality: "",
          administrative_area: "",
          postal_code: "",
        },
      ],
      field_contact_name = [{ value: "" }],
      field_customer_auto_reply = [{ value: "" }],
      field_customer_auto_reply_text = [{ value: "" }],
      field_customer_operating_hours = [{ from: 0, to: 0 }],
      field_customer_timezone = [{ vale: "" }],
      field_email = [{ value: "" }],
      field_customer_contact = [{ value: "" }],
      field_customer_form_embed = [{ value: "" }],
    } = this.state.data;

    const tableData = submissions_by_customer.map((element) => {
      const { phone } = JSON.parse(element.data);
      //let lastCommentDate = formatDate(last_comment_date);
      let createdDate = element.created;

      // if(lastCommentDate === "Invalid Date") {
      //   lastCommentDate = "No Response";
      //   return [];
      // }

      return {
        date: createdDate,
        // lastCommentDate: lastCommentDate,
        contact: phone,
        id: <Link to={`/messages/view/${element.sid}`}> View Message </Link>,
      };
    });

    //console.log(tableData);

    return (
      <AccountPageWrapper>
        <Container>
          <>
            <Row>
              <Col>
                <H2>
                  {title[0].value}
                  <Link
                    style={{
                      float: `right`,
                      fontSize: `54%`,
                      fontWeight: 500,
                      display: `inline`,
                      margin: `0 10px`,
                    }}
                    to={`/accounts/edit/${this.props.match.params.id}`}
                  >
                    Edit Account
                  </Link>
                  <span
                    style={{ float: `right`, fontSize: `54%`, fontWeight: 500 }}
                  >
                    |
                  </span>
                  <Link
                    style={{
                      float: `right`,
                      fontSize: `54%`,
                      fontWeight: 500,
                      display: `inline`,
                      marginRight: `10px`,
                    }}
                    customer={this.state.data}
                    to={`/accounts/contact_manager/${this.props.match.params.id}`}
                  >
                    Contact Manager
                  </Link>
                </H2>
              </Col>
            </Row>
            <AccountSection>
              <Row>
                <Col className={`field-label`} xs={2} s={1}>
                  <Label>Contact</Label>
                </Col>
                <Col xs={2} s={3}>
                  {field_contact_name[0].value}
                </Col>
              </Row>
              <Row>
                <Col className={`field-label`} xs={2} s={1}>
                  <Label>Customer Address</Label>
                </Col>

                <Col xs={2} s={3}>
                  {field_address[0].address_line1}
                  <br />
                  {`${
                    field_address[0].locality.length > 0 &&
                    `${field_address[0].locality},`
                  } ${field_address[0].administrative_area} ${
                    field_address[0].postal_code
                  }`}
                </Col>
              </Row>
              <Row>
                <Col className={`field-label`} xs={2} s={1}>
                  <Label>Email</Label>
                </Col>
                <Col xs={2} s={3}>
                  {field_email[0].value}
                </Col>
              </Row>
              <Row>
                <Col className={`field-label`} xs={2} s={1}>
                  <Label>Twilio Contact Number</Label>
                </Col>{" "}
                <Col xs={2} s={3}>
                  {field_customer_contact[0].value}
                </Col>
              </Row>
              <Row>
                <Col xs={2} s={4}>
                  <Label>Customer Notes</Label>
                  <Notes
                    dangerouslySetInnerHTML={{
                      __html: field_customer_client_notes
                        ? field_customer_client_notes[0].value
                        : `<p>No Client Notes Found.</p>`,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className={`field-label`} xs={2} s={1}>
                  <Label>Operating Hours</Label>
                </Col>
                <Col xs={2} s={3}>{`${secondsToTime(
                  field_customer_operating_hours[0].from
                )} - ${secondsToTime(
                  field_customer_operating_hours[0].to
                )}`}</Col>
              </Row>
              <Row>
                <Col className={`field-label`} xs={2} s={1}>
                  <Label>Timezone</Label>
                </Col>
                <Col xs={2} s={3}>
                  {getTimezoneName(field_customer_timezone[0].value)}
                </Col>
              </Row>
              <Row>
                <Col className={`field-label`} xs={2} s={1}>
                  <Label>Auto-Reply</Label>
                </Col>
                <Col xs={2} s={3}>
                  {field_customer_auto_reply[0].value > 0 ? "Yes" : "No"}
                </Col>
              </Row>
              {field_customer_auto_reply[0].value > 0 && (
                <Row>
                  <Col xs={2} s={4}>
                    <Label>Auto-reply Text </Label>
                    <Notes
                      dangerouslySetInnerHTML={{
                        __html: field_customer_auto_reply_text[0].value,
                      }}
                    />
                  </Col>
                </Row>
              )}
              {field_customer_form_embed[0].value.length > 0 && (
                <Row>
                  <Col xs={2} s={4}>
                    <Label>Embed Code</Label>
                    <textarea
                      style={{ margin: `15px`, fontSize: `.9rem` }}
                      disabled
                      defaultValue={`<iframe title="Embed code for ${
                        title[0].value
                      }." src="${`${host}/webform/embedding/${field_customer_form_embed[0].value}/${this.props.match.params.id}`}" style="width:100%; height:100vw; border: 0;”></iframe>`}
                      rows="3"
                      cols="15"
                    ></textarea>
                  </Col>
                </Row>
              )}
            </AccountSection>

            <Row>
              <Col>
                <AccountSection>
                  <H3>Messages</H3>
                  {tableData.length > 0 ? (
                    <Table
                      headers={[
                        "Message Date",
                        // "Last Reply Date",
                        "Phone",
                        "View Message",
                      ]}
                      data={tableData.sort(
                        ({ lastCommentDate: a }, { lastCommentDate: b }) =>
                          new Date(b) - new Date(a)
                      )}
                    />
                  ) : (
                    <p>No Messages.</p>
                  )}
                </AccountSection>
              </Col>
            </Row>
          </>
        </Container>
      </AccountPageWrapper>
    );
  }
}

export default withRouter(AccountView);
