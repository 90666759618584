import React, { Component } from "react";
import { Button } from "../../styles/theme";
import {
  getRenderedMessage,
  sendMessageToCustomer,
  sendSMSToUser
} from "../../data/fetchers";
import Message from "../../components/message";
import Comments from "../../components/comments";

import { SubmissionWrapper } from "../../styles/layout-styles";
import { MoonLoader } from "react-spinners";

class View extends Component {
  state = {
    loading: true,
    data: null,
    replyBox: false,
    id: null,
    message: "",
    commentTrigger: false
  };
  async componentDidMount() {
    const hashed = sessionStorage.getItem("auth");
    const { id } = this.props.match.params;
    if (hashed) {
      const data = await getRenderedMessage(hashed, id);

      this.setState({ loading: false, data, id });
    }
  }
  handleClick = () => {
    const newState = { ...this.state };
    newState.replyBox = !this.state.replyBox;
    this.setState(newState);

    window.scrollTo({ top: 1000, behavior: "smooth" });
  };
  handleEmailClick = async id => {
    const hashed = sessionStorage.getItem("auth");
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(`Are you sure you wish to email this message to the customer?`) &&
      hashed
    ) {
      const results = await sendMessageToCustomer(hashed, id);

      results.success
        ? alert(results.success)
        : alert("Unable to email message.");
    }
  };
  handleMessageClick = async () => {
    const hashed = sessionStorage.getItem("auth");
    const { message, id } = this.state;
    const body = JSON.stringify({ message });
    if (hashed) {
      const results = await sendSMSToUser(hashed, id, body);
      alert(results.success);

      const newState = { ...this.state };
      newState.replyBox = false;
      newState.commentTrigger = true;
      newState.message = "";
      this.setState(newState);
    }
  };
  handleMessageChange = e => {
    const newState = { ...this.state };
    newState.message = e.target.value;
    this.setState(newState);
  };
  updateTrigger = () => {
    const newState = { ...this.state };
    newState.commentTrigger = !this.state.commentTrigger;
    this.setState(newState);
  };
  render() {
    if (this.state.loading) return <MoonLoader/>
    return (
      <div>
        <SubmissionWrapper
          dangerouslySetInnerHTML={{
            __html: this.state.data[0].rendered_entity
          }}
        />
        <Comments
          match={{ ...this.props.match }}
          trigger={this.state.commentTrigger}
          resolveTrigger={this.updateTrigger}
        />
        <Button
          onClick={this.handleClick}
          style={{
            width: `25%`,
            float: `left`,
            margin: `10px`,
            fontWeight: `500`
          }}
          active
        >
          {this.state.replyBox ? "Cancel Txt Reply" : "Send Txt Reply"}
        </Button>{" "}
        <Button
          onClick={() => this.handleEmailClick(this.state.id)}
          style={{
            width: `25%`,
            float: `left`,
            margin: `10px`,
            fontWeight: `500`
          }}
          active
        >
          Email Message to Business Owner
        </Button>
        {this.state.replyBox && (
          <Message
            onChange={this.handleMessageChange}
            onClick={this.handleMessageClick}
          />
        )}
      </div>
    );
  }
}

export default View;
