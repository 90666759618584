import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import { Label } from "../../../styles/theme";
import ContactImport from "../../../components/contactimport";
import {
  getCustomerContactsByAccount,
  sendTXTBatch,
  deleteCustomersById,
} from "../../../data/fetchers";
import {
  Grommet,
  Box,
  ThemeContext,
  CheckBox,
  Button,
  grommet,
  Heading,
  FileInput,
} from "grommet";
import { DataTable } from "grommet/components/DataTable";
// import Data from "../../../data/testdata";
import styled from "styled-components";
import { MoonLoader } from "react-spinners";

const Textarea = styled.textarea`
  overflow: auto;
  width: 75%;
  height: 215px;
  border: 3px solid ${(props) => props.theme.green};
  padding: 5px;
`;
class ContactManager extends Component {
  state = {
    // select: [],
    checked: [],
    data: [],
    all: false,
    loading: true,
    importData: [],
    import: false,
    page: 0,
    loadingAPI: true,
  };

  onCheck = (event, value) => {
    if (event.target.checked) {
      this.setState({ checked: [...this.state.checked, value], all: false });
    } else {
      this.setState({
        checked: this.state.checked.filter((item) => item !== value),
        all: false,
      });
    }
  };

  onCheckAll = (event) => {
    this.setState({
      checked: event.target.checked
        ? this.state.data.filter((d) => !d.optout).map((datum) => datum.id)
        : [],
      all: !this.state.all,
    });
  };
  handleToggleImport = (e) => {
    const newState = {
      ...this.state,
      import: !this.state.import,
    };
    this.setState(newState);
  };

  async componentDidMount() {
    this.fetchContacts();
  }

  fetchContacts = async () => {
    const hashed = sessionStorage.getItem("auth");
    const { id } = this.props.match.params;
    if (hashed) {
      const data = await getCustomerContactsByAccount(hashed, id, 0);
      const newState = { ...this.state };
      newState.data = data.map((e, i) => ({
        id: e.nid[0].value,
        fname: e.hasOwnProperty("field_firstname")
          ? e.field_firstname[0].value
          : "",
        lname: e.hasOwnProperty("field_lastname")
          ? e.field_lastname[0].value
          : "",
        phone: e.hasOwnProperty("field_phone") ? e.field_phone[0].value : "",
        email: e.hasOwnProperty("field_email") ? e.field_email[0].value : "",
        optout: e.hasOwnProperty("field_opted_out")
          ? e.field_opted_out[0].value
          : false,
      }));

      //newState.loading = false;
      this.setState(newState);
    }
  };

  // Function checks for recently imported contacts and preselects values imported.
  preCheck(importValues) {
    this.fetchContacts();
    // First lets merge the duplicates and the imported new contacts.
    if (importValues) {
      // Merge arrays within keys created_items_ids and duplicates_found
      const { created_items_ids, duplicates_found } = importValues;
      const merged = created_items_ids.concat(duplicates_found);

      // convert all values in merged to integers
      const mergedInt = merged.map(Number);

      const newState = {
        ...this.state,
        checked: mergedInt,
      };
      this.setState(newState);
    }
  }
  async componentDidUpdate() {
    if (this.state.loadingAPI) {
      const allAPIData = await this.getMoreData();
      const newState = { ...this.state };
      newState.data = allAPIData.flat();
      newState.loadingAPI = false;
      this.setState(newState);
    }
  }

  getMoreData = async () => {
    const hashed = sessionStorage.getItem("auth");
    const { id } = this.props.match.params;
    var gotData = true;

    const allData = [];
    var pageNum = this.state.page;
    if (hashed) {
      while (gotData) {
        const data = await getCustomerContactsByAccount(hashed, id, pageNum);

        // const newState = { ...this.state };
        // newState.page = newState.page + 1;
        if (data.length === 0) {
          gotData = false;
          // Set the loading state to false
          const newState = { ...this.state };
          newState.loading = false;
          this.setState(newState);
          return [...allData];
        }
        if (data.length > 0) {
          const cleanData = [
            ...data.map((e, i) => ({
              id: e.nid[0].value,
              fname: e.hasOwnProperty("field_firstname")
                ? e.field_firstname[0].value
                : "",
              lname: e.hasOwnProperty("field_lastname")
                ? e.field_lastname[0].value
                : "",
              phone: e.hasOwnProperty("field_phone")
                ? e.field_phone[0].value
                : "",
              email: e.hasOwnProperty("field_email")
                ? e.field_email[0].value
                : "",
              optout: e.hasOwnProperty("field_opted_out")
                ? e.field_opted_out[0].value
                : false,
            })),
          ];

          allData.push(cleanData);
          pageNum++;
        }
      }
    }
  };

  removeContacts = async (ids) => {
    const hashed = sessionStorage.getItem("auth");
    const newState = { ...this.state };

    if (hashed) {
      const { deleted_items_count } = await deleteCustomersById(hashed, ids);
      if (deleted_items_count === ids.length) {
        const updateData = [...this.state.data];
        ids.forEach((e) => {
          const index = updateData.findIndex(({ id }) => id === e);
          updateData.splice(index, 1);
        });
        newState.data = [...updateData];
      }
      newState.loading = false;
      newState.checked = [];
      this.setState(newState);
    }
  };

  render() {
    if (this.state.loading) return <MoonLoader></MoonLoader>;
    const columnConfig = [
      {
        property: "fname",
        header: "First Name",
        footer: "Contacts selected",
        search: true,
      },
      {
        property: "lname",
        header: "Last Name",
        footer: `${this.state.checked.length}`,
        search: true,
      },
      {
        property: "phone",
        header: "Phone Number",
        search: true,
      },
      {
        property: "email",
        header: "Email",
        search: true,
      },
      {
        property: "optout",
        header: "Opt Out",
        sortable: true,
        render: ({ optout }) => (optout ? "X" : ""),
      },
      {
        property: "checkbox",
        render: (datum) => {
          return (
            <CheckBox
              key={datum.id}
              checked={this.state.checked.indexOf(datum.id) !== -1}
              onChange={(e) => this.onCheck(e, datum.id)}
              disabled={datum.optout}
            />
          );
        },
        header: (
          <CheckBox
            disabled={this.state.loadingAPI}
            checked={this.state.all}
            indeterminate={
              !this.state.all &&
              this.state.checked.length > 0 &&
              this.state.checked.length < this.state.data.length
            }
            onChange={this.onCheckAll}
          />
        ),
      },
    ];
    const { id } = this.props.match.params;
    return (
      <Grommet theme={grommet}>
        <Heading level="2">Contact Manager</Heading>
        <Formik
          initialValues={{
            customers: "",
            id: "", //account ID.
            body: "\n\n\n\nReply STOP to Opt out",
            mediaUrl: "",
          }}
          onSubmit={async (
            values,
            { setSubmitting, setErrors, setStatus, resetForm }
          ) => {
            const hashed = sessionStorage.getItem("auth");
            if (hashed) {
              try {
                if (values.badFile) {
                  alert(
                    "Please upload a valid PNG or JPG file the is less than 2MB"
                  );
                  return;
                }

                const contacts = this.state.checked.map((e, i) => {
                  const { fname, lname, phone, email } = this.state.data.find(
                    (d) => d.id === e
                  );
                  return {
                    name: `${fname} ${lname}`,
                    phone,
                    email,
                  };
                });

                const results = {
                  contacts,
                  id,
                  message: values.body,
                  mediaUrl: values.image,
                };
                if (results) {
                  await sendTXTBatch(hashed, results);
                  setSubmitting(false);
                  resetForm();
                  alert("Messages Sent");
                  window.location.reload();
                }
              } catch (e) {
                setSubmitting(false);
                console.log(e.message);
                setErrors({ error: e.message });
              }
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }
          }}
          render={(props) => {
            return (
              <Form>
                <ThemeContext.Extend
                  value={{
                    global: { colors: { brand: `#6BB74C` } },
                    button: {
                      size: { medium: { border: { radius: `0px` } } },
                    },
                  }}
                >
                  <Box>
                    <Box padding="medium" direction="row" justify="end">
                      <Box direction="row" gap="small">
                        <Button
                          label={
                            this.state.import
                              ? `View Contacts`
                              : `Import Contacts`
                          }
                          primary
                          type="button"
                          size="medium"
                          onClick={(e) => this.handleToggleImport(e)}
                        />
                        {!this.state.import && (
                          <Button
                            type="button"
                            size="medium"
                            disabled={this.state.checked.length === 0}
                            primary
                            color={"red"}
                            onClick={(e) => {
                              const newState = { ...this.state };
                              newState.loading = true;
                              this.setState(newState);
                              if (
                                window.confirm(
                                  "Are you sure you want to delete contacts?"
                                )
                              ) {
                                this.removeContacts(this.state.checked);
                              }
                            }}
                            label={`Delete ${
                              this.state.checked.length
                            } Contact${
                              this.state.checked.length === 1 ? "" : "s"
                            }`}
                          />
                        )}
                      </Box>
                    </Box>
                    {!this.state.import ? (
                      <DataTable
                        columns={columnConfig}
                        data={this.state.data}
                        primaryKey="id"
                        paginate
                        step={25}
                      />
                    ) : (
                      <>
                        Import a .CSV file with the following columns:
                        firstname, lastname, phone, email
                        <strong>Do not include header row.</strong>
                        <ContactImport
                          customer={id}
                          cancelImport={this.handleToggleImport}
                          setImportDisplay={(value, importValues) => {
                            const newState = { ...this.state };
                            newState.import = value;
                            this.setState(newState);

                            this.preCheck(importValues);
                          }}
                        />
                      </>
                    )}
                  </Box>
                  {!this.state.import && (
                    <Box>
                      <Box width="medium">
                        <Label>Image File</Label>
                        <div style={{ fontSize: ".7em", marginLeft: "5px" }}>
                          (.JPG or .PNG less then 2MB in file size)
                        </div>
                        <FileInput
                          name="image"
                          maxSize={200000}
                          multiple={false}
                          accept=".png, .jpg"
                          onChange={async (event) => {
                            const file = event.target.files[0];
                            if (
                              (file.type === "image/png" ||
                                file.type === "image/jpeg") &&
                              file.size <= 2000000
                            ) {
                              const reader = new FileReader();
                              reader.readAsDataURL(file);
                              let base64String;

                              reader.onload = function () {
                                base64String = reader.result;
                                props.setFieldValue("image", base64String);
                                props.setFieldValue("fileType", file.type);
                                props.setFieldValue("badFile", false);
                              };
                            } else {
                              alert(
                                "Please upload a valid PNG or JPG file the is less than 2MB"
                              );
                              props.setFieldValue("badFile", true);
                            }
                          }}
                        />
                        {/* <Field name="mediaUrl" type="text" /> */}
                      </Box>
                      <Box>
                        <Label>Message</Label>
                        <Textarea
                          name="body"
                          defaultValue={props.initialValues.body}
                          onChange={(e) =>
                            props.setFieldValue("body", e.target.value)
                          }
                        />
                      </Box>
                      <Box width="large" direction="row">
                        <Button
                          type="submit"
                          size="medium"
                          margin="small"
                          disabled={this.state.loadingAPI}
                          primary
                          label={`Send Message to ${
                            this.state.checked.length
                          } User${this.state.checked.length === 1 ? "" : "s"}`}
                        />
                      </Box>
                    </Box>
                  )}
                </ThemeContext.Extend>
              </Form>
            );
          }}
        />
      </Grommet>
    );
  }
}
export default withRouter(ContactManager);
