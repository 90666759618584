export const host = `https://thumpsms.mystack.co`;

export const getAccounts = async (hashed) => {
  const endpoint = `/api/v1/customers?_format=hal_json`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    Accept: "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "GET",
    headers,
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must Login.");
      }
      return JSON.parse(JSON.stringify(await res.json()));
    });
};
export const getAccountById = async (hashed, id) => {
  const host = `https://thumpsms.mystack.co/`;
  const endpoint = `/node/${id}?_format=hal_json`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    Accept: "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "GET",
    headers,
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must Login.");
      }
      return JSON.parse(JSON.stringify(await res.json()));
    });
};
export const getMessages = async (hashed) => {
  const endpoint = `/api/v1/messages?_format=hal_json`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    Accept: "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "GET",
    headers,
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must Login.");
      }
      return JSON.parse(JSON.stringify(await res.json()));
    });
};
export const numberOfNewMessages = async (hashed, id) => {
  const endpoint = `/api/v1/submissions/${id}?_format=hal_json`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    Accept: "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "GET",
    headers,
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must Login.");
      }
      const data = JSON.parse(JSON.stringify(await res.json()));
      return data.length;
    });
};

export const getSubmissionsByCustomer = async (hashed, id) => {
  const endpoint = `/api/v1/submissions/${id}?_format=hal_json`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    Accept: "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "GET",
    headers,
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must Login.");
      }
      return JSON.parse(JSON.stringify(await res.json()));
    });
};

export const getAllMessages = async (hashed) => {
  //const endpoint = `/api/v1/submissions/all?_format=hal_json`;
  const endpoint = `/api/v3/messages/all`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    Accept: "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "GET",
    headers,
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must Login.");
      }
      return res.json();
    });
};

export const getRenderedMessage = async (hashed, id) => {
  const endpoint = `/api/v1/rendered/submission/${id}?_format=hal_json`;

  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    Accept: "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "GET",
    headers,
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must Login.");
      }
      return await res.json();
    });
};

export const sendMessageToCustomer = async (hashed, id) => {
  const endpoint = `/api/v1/notify/email/${id}?_format=hal_json`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    Accept: "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "GET",
    headers,
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must be logged in to email");
      }

      return await res.json();
    });
};

export const sendSMSToUser = async (hashed, id, body) => {
  const endpoint = `/api/v1/notify/submitter/sms/${id}`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    Accept: "application/json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "POST",
    headers,
    body,
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must be logged in to send message.");
      }
      return await res.json();
    });
};

export const getSubmissionCommentsByID = async (hashed, id) => {
  const endpoint = `/api/v1/submission/comments/${id}?_format=hal_json`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    Accept: "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "GET",
    headers,
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must be logged in to send message.");
      }
      return await res.json();
    });
};

export const createNewAccount = async (hashed, data) => {
  const endpoint = `/node?_format=hal_json`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    "Content-Type": "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 201) {
        return await res.json();
      }
      if (res.status === 403) {
        throw Error("Must be logged in to create a new Account.");
      }

      throw Error(res.statusText);
      // return false;
    });
};

export const updateAccountData = async (hashed, data, id) => {
  const endpoint = `/node/${id}?_format=hal_json`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    "Content-Type": "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "PATCH",
    headers,
    body: JSON.stringify(data),
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 200) {
        return await res.json();
      }
      if (res.status === 403) {
        throw Error("Must be logged in to edit an Account.");
      }

      throw Error(res.statusText);
      // return false;
    });
};

export const createCustomerContacts = async (hashed, data) => {
  const endpoint = `/api/v1/contact/create`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    "Content-Type": "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 200) {
        return await res.json();
      }
      if (res.status === 403) {
        throw Error("Must be logged in to import contacts.");
      }

      throw Error(res.statusText);
      // return false;
    });
};

export const sendTXTBatch = async (hashed, data) => {
  const endpoint = `/api/v1/contacts/send/mms`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    "Content-Type": "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 200) {
        return await res.json();
      }
      if (res.status === 403) {
        throw Error("Must be logged in to send messages.");
      }

      throw Error(res.statusText);
      // return false;
    });
};

export const getCustomerContactsByAccount = async (hashed, id, page) => {
  const endpoint = `/api/v1/contacts/${id}?_format=hal_json${
    page >= 0 ? `&page=${page}` : ""
  }`;
  console.log(endpoint);
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    Accept: "application/hal+json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "GET",
    headers,
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must be logged in to view Users.");
      }
      return await res.json();
    });
};

export const deleteCustomersById = async (hashed, ids) => {
  const endpoint = `/api/v1/contact/delete`;
  const headers = new Headers({
    Authorization: `Basic ${hashed}`,
    "Content-Type": "application/json",
  });

  return await fetch(`${host}${endpoint}`, {
    method: "POST",
    headers,
    body: JSON.stringify(ids),
    redirect: "follow",
  })
    .catch((error) => error)
    .then(async (res) => {
      if (res.status === 403) {
        throw Error("Must be logged in to view Users.");
      }
      return await res.json();
    });
};
